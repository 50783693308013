<template>
  <section ref="editor" style="text-align:left"></section>
</template>

<script>
import environments from "@/config/url";
import Editor from "wangeditor";
import { encryptByDES} from '@/utils/3DES'
export default {
  name: "JEditor",
  props: {
    type: {
      type: String,
      default: ""
    },
     content: {
      type: String,
      default: ""
    },
    inintTxt: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      editorContent: "",
      editorContainer: null
    };
  },

  mounted() {
    this.initEditor();
  },
  watch: {
    inintTxt() {
      this.setContent(this.inintTxt);
    },
     content(){
      this.initEditor();
    }
  },

  methods: {
    initEditor() {
      let editor = new Editor(this.$refs.editor);
      this.editorContainer = editor;
      editor.customConfig.onchange = html => {
					// 派发监听 Editor 输入的值
					this.$emit('handleEditContent', html, this.type,editor.txt.text())
				}
      editor.customConfig.pasteTextHandle = function(content) {
        return content;
      };
      const TOKEN = encryptByDES(encryptByDES((new Date().getTime()).toString(),'63e42c2444e94c1ebca21d30d2aa39a5'),localStorage.getItem('token') || '')
      // 上传图片到服务器
      editor.customConfig.uploadImgServer = environments[process.env.VUE_APP_BASE_URL].multiUploadBaseUrl;
      editor.customConfig.uploadImgHeaders = {'ACCESS_TOKEN': localStorage.getItem('token'),'channel':'platformPc','TOKEN':TOKEN};
      // 将图片大小限制为 3M
      editor.customConfig.uploadImgMaxSize = 3 * 1024 * 1024;
      // 自定义 fileName
      editor.customConfig.uploadFileName = "files";

      editor.customConfig.uploadImgHooks = {
        customInsert(insertImg, result) {
          if (!result.filesUrl.length) return;
          result.filesUrl.forEach(url => {
            insertImg(url);
          });
        }
      };

      editor.create();
      if (this.inintTxt) {
        this.setContent(this.inintTxt);
      }

      // 派发设置 Editor 的值
      this.$emit("handleSettingEditContent", editor.txt, this.type);
    },
    setContent(val) {
      this.editorContainer.txt.html(val);
    }
  }
};
</script>
